<template>
    <v-list-item-content>
        <v-list-item-title>
        <template v-if="link">
            <span><router-link :to="{ name: 'cryptium_id-realm-user-view', params: { enterpriseId, realmId, userId: attr.id } }">{{ displayName }}</router-link></span>
        </template>
        <template v-if="!link">
            <span>{{ displayName }}</span>
        </template>
        </v-list-item-title>
        <v-list-item-subtitle>
        <span class="text-caption">{{ id }}</span>
        </v-list-item-subtitle>
    </v-list-item-content>
</template>

<script>
export default {

    props: {
        // when true, the item is a link to the user view
        link: {
            type: Boolean,
            default: false,
        },
        enterpriseId: {
            type: String,
        },
        realmId: {
            type: String,
        },
        attr: {
            type: Object,
        },
    },

    computed: {
        id() {
            return this.attr.id;
        },
        displayName() {
            return this.attr.display_name;
        },
    },

};
</script>
