<template>
    <v-list dense nav>
        <template v-if="isViewReady">
            <v-list-item v-if="list.length === 0">No users.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <RealmUserListItem :attr="item" :enterpriseId="enterpriseId" :realmId="realmId" :link="!selectOne"></RealmUserListItem>
            </v-list-item>
        </template>
    </v-list>
</template>

<script>
import RealmUserListItem from '@/components/realm-dashboard/RealmUserListItem.vue';

export default {
    components: {
        RealmUserListItem,
    },

    props: {
        // optional; use this when you want to use the realm list to select an realm; emits 'selected' when user taps on an realm
        selectOne: {
            type: Boolean,
            default: false,
        },
        enterpriseId: {
            type: String,
        },
        realmId: {
            type: String,
        },
    },

    data: () => ({
        list: null,
    }),

    computed: {
        isViewReady() {
            return Array.isArray(this.list);
        },
    },

    methods: {
        async loadRealmUserList() {
            try {
                this.$store.commit('loading', { loadRealmUserList: true });
                const response = await this.$client.cryptiumIdRealm(this.$route.params.enterpriseId, this.$route.params.realmId).realmUser.search(/* { realm_id: this.$route.params.realmId } */);
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadRealmUserList failed', err);
            } finally {
                this.$store.commit('loading', { loadRealmUserList: false });
            }
        },
        onClickItem(realmId) {
            if (this.selectOne) {
                this.$emit('selected', { realmId });
            }
        },
    },

    mounted() {
        this.loadRealmUserList();
    },
};
</script>
